<template>
  <div>
    <area-cascader v-model="area"></area-cascader>
    <el-button style="margin-left:10px" type="primary" @click="save" :loading="loading">确定</el-button>
  </div>
</template>

<script>
import AreaCascader from "@/components/AreaCascader/AreaCascader";
import { mapState } from "vuex";
export default {
  components: {
    AreaCascader,
  },
  data() {
    return {
      loading: false,
      area: [],
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.area = [this.userInfo.province, this.userInfo.city];
  },
  methods: {
    save() {
      this.loading = true;
      this.$post("Sys/SetArea", {
        province: this.area[0],
        city: this.area[1],
      })
        .then((r) => {
          this.$message.success("操作成功！");
          this.$store.commit("setArea", {
            province: this.area[0],
            city: this.area[1],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>