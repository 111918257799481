<template>
  <div>
    <div v-if="hasPermission('UserCharge')">
      <div class="title">
        <strong>
          <i class="iconfont iconmoney" style="font-size:14px"></i>
          续费充值
        </strong>
      </div>
      <el-card>
        <UserCharge></UserCharge>
      </el-card>
    </div>
    <div v-if="hasPermission('SetCity')">
      <div class="title">
        <strong>
          <i class="el-icon-location-information"></i>
          默认城市
        </strong>
      </div>
      <el-card>
        <SetCity></SetCity>
      </el-card>
    </div>
    <div v-if="hasPermission('PrintSet')">
      <div class="title">
        <strong>
          <i class="el-icon-printer"></i>
          打印设置
        </strong>
      </div>
      <el-card>
        <SetPrint></SetPrint>
      </el-card>
    </div>
  </div>
</template>

<script>
import SetCity from "./SetCity";
import SetPrint from "./Print";
import UserCharge from './UserCharge'
export default {
  components: {
    SetCity,
    SetPrint,
    UserCharge
  },
};
</script>

<style scoped>
.title {
  margin: 10px;
}
</style>