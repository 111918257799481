<template>
  <div>
    <el-upload
      action
      :auto-upload="false"
      :show-file-list="false"
      :on-change="handleChange"
    >
      <el-button slot="trigger" icon="el-icon-upload2" type="primary">上传打印样式</el-button>
    </el-upload>
    <div style="margin:10px"></div>
    <el-table :data="tableData" stripe border>
      <el-table-column label="单据名称" prop="billName" align="center"></el-table-column>
      <el-table-column label="保存自动打印" align="center">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.savePrint?'success':'info'"
            @click="editSet(scope.row)"
          >{{scope.row.savePrint?'是':'否'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="打印样式" align="center">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.selfTemplate?'success':'info'"
          >{{scope.row.selfTemplate?'自定义':'默认'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="下载模板" align="center">
        <template slot-scope="scope">
          <el-tag type="success" @click="downloadTemplate(scope.row)">
            <i class="el-icon-download"></i>
          </el-tag>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="打印设置" :visible.sync="visible" append-to-body v-dialogDrag width="30%">
      <el-form label-width="90px">
        <el-form-item label="单据名称" prop="oldPwd">
          <el-input placeholder="单据名称" v-model="item.billName" readonly></el-input>
        </el-form-item>
        <el-form-item label="保存后打印" prop="oldPwd">
          <el-radio-group v-model="item.savePrint">
            <el-radio :label="true" border>是</el-radio>
            <el-radio :label="false" border>否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSave" :loading="loading">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import oss from "@/common/oss";
export default {
  data() {
    return {
      visible: false,
      loading: false,
      item: {},
    };
  },
  computed: {
    tableData() {
      let res = [];
      this.$store.state.userInfo.printSet.forEach((item) => {
        res.push({ ...item });
      });
      return res;
    },
  },
  methods: {
    editSet(row) {
      this.item = { ...row };
      this.visible = true;
    },
    downloadTemplate(row) {
      let file = row.selfTemplate
        ? this.$store.state.userInfo.companyId + "/" + row.billName + ".grf"
        : row.billName + ".grf";
      oss("zxhd-print").then((client) => {
        const url = client.signatureUrl(file);
        const elt = document.createElement("a");
        elt.setAttribute("href", url);
        elt.style.display = "none";
        document.body.appendChild(elt);
        elt.click();
        document.body.removeChild(elt);
      });
    },
    handleChange(file) {
      let set;
      let type = file.name.split(".")[0];
      this.tableData.forEach((item) => {
        if (item.billName == type) {
          set = { ...item };
        }
      });
      if (!set) {
        this.$message.error("上传文件类型错误");
        return;
      }
      let key = this.$store.state.userInfo.companyId + "/" + file.name;
      oss("zxhd-print").then((client) => {
        client.put(key, file.raw).then(() => {
          this.$post("Print/SelfTemplate?id=" + set.id).then(() => {
            set.selfTemplate = true;
            this.$store.commit("setPrint", set);
            this.$message.success("设置成功！");
          });
        });
      });
    },
    handleSave() {
      this.loading = true;
      let set = { ...this.item };
      let url = set.savePrint ? "Print/SetSavePrint" : "Print/CancelSavePrint";
      this.$post(url + "?id=" + set.id)
        .then(() => {
          this.$store.commit("setPrint", set);
          this.$message.success("设置成功！");
          this.visible = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>