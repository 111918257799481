<template>
  <div>
    <div>
      <span>用户数：</span>
      <span class="item">{{ userInfo.userNumber }}</span>
      <el-popover placement="top">
        <div>
          <el-input-number
            v-model="userNumber"
            :min="1"
            step-strictly
            :precision="0"
          ></el-input-number>
          <el-button type="primary" class="ml10" @click="changeUserNumber"
            >确定</el-button
          >
        </div>

        <el-button class="ml10" type="text" slot="reference"
          >变更用户数</el-button
        >
      </el-popover>
    </div>
    <div>
      <span>到期日：</span>
      <span class="item">{{ userInfo.expireDate | datetime }}</span>
      <span class="ml10">剩余：</span>
      <span class="item">{{ userInfo.remainDays }}天</span>
      <el-popover placement="top">
        <div>
          <el-select v-model="chargeYear">
            <el-option
              v-for="i in 5"
              :key="i"
              :label="i + '年'"
              :value="i"
            ></el-option>
          </el-select>
          <el-button type="primary" class="ml10" @click="addYear"
            >确定</el-button
          >
        </div>
        <el-button class="ml10" type="text" slot="reference"
          >延期续费</el-button
        >
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      userNumber: this.$store.state.userInfo.userNumber,
      chargeYear: 1,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    changeUserNumber() {
      if (this.userNumber == this.userInfo.userNumber) {
        this.$message.error("用户数未改变");
        return;
      }
      if (this.userNumber < 1) {
        this.$message.error("用户数不能小于1");
        return;
      }
      this.$alert(
        `将用户数更改为【${this.userNumber}】个，是否确认？`,
        "提示",
        {
          confirmButtonText: "确定",
          callback: (action) => {
            if (action == "confirm") {
              if (this.userNumber < this.userInfo.userNumber) {
                this.decUserNumber();
              } else {
                this.addUserNumber();
              }
            } else {
              this.userNumber = this.userInfo.userNumber;
            }
          },
        }
      );
    },
    decUserNumber() {
      this.$post("UserCharge/DecUserNumber?userNumber=" + this.userNumber).then(
        (r) => {
          this.$message.success("操作成功");
          this.$store.commit("setExpireDate", r);
          this.$store.commit("setUserNumber", this.userNumber);
        }
      );
    },
    addUserNumber() {
      this.$post("UserCharge/AddUserNumber?userNumber=" + this.userNumber).then(
        (page) => {
          let pay = window.open();
          pay.document.write(page);
          this.$alert("完成充值，刷新数据", "完成充值", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$get("UserCharge/GetUserNumberAndExpireDate").then((r) => {
                this.$store.commit("setExpireDate", r.expire);
                this.$store.commit("setUserNumber", r.userNumber);
                this.userNumber = r.userNumber;
              });
            },
          });
        }
      );
    },
    addYear() {
      this.$post("UserCharge/AddYear?year=" + this.chargeYear).then((page) => {
        let pay = window.open();
        pay.document.write(page);
        this.$alert("完成充值，刷新数据", "完成充值", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.$get("UserCharge/GetUserNumberAndExpireDate").then((r) => {
              this.$store.commit("setExpireDate", r.expire);
            });
          },
        });
      });
    },
  },
};
</script>

<style scoped>
.item {
  margin-left: 8px;
  color: #67c23a;
}
</style>